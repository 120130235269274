/* You can add global styles to this file, and also import other style files */

@import url('https://fonts.googleapis.com/css?family=Lato');
@import url('https://fonts.googleapis.com/css?family=Open+Sans');

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Lato', Helvetica, Arial, Lucida, sans-serif;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}
